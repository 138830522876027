//演示环境
var requestUrl, authHost, tpUrl, shopUrl;
let env = 1;

if (env == 0) {
	// requestUrl = 'http://www.allnfts.cn';
	requestUrl = 'http://81.69.172.13:8081/'
	authHost = 'http://zhongchou.metacbc.cn';
	tpUrl = "http://test.metacbc.cn";
	shopUrl = "https://shop.metacbc.cn"
} else {
	//线上环境
	requestUrl = 'https://nft.allnfts.cn';
	authHost = 'https://money.metacbc.cn';
	tpUrl = "https://dao.metacbc.cn";
	shopUrl = "https://shop.metacbc.cn"
}

//固定参数
const appId = 'wx23406d34d48c253c';
const host = authHost + '/';
const authUrl = 'https://auth.allnfts.cn'
export default {
	appId,
	requestUrl,
	authHost,
	host,
	authUrl,
	tpUrl,
	shopUrl
}

