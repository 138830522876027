<template>
	<div id="app">
		<keep-alive>
			<router-view :key="key" v-if="$route.meta.keepAlive" />
		</keep-alive>
		<router-view :key="key" v-if="!$route.meta.keepAlive" />
	</div>
</template>
<script>
	export default {
		name: 'myApp',
		computed: {
			key() {
				return this.$route.fullPath
			}
		}
	}
</script>
<style>
	@font-face {
		font-family: VonwaonBitmap;
		src: url("./assets/fonts/VonwaonBitmap.ttf");
	}

	html {
		margin: 0 !important;
		padding: 0 !important;
	}

	body {
		margin: 0 !important;
		padding: 0 !important;
		-webkit-text-size-adjust: 100% !important;
		text-size-adjust: 100% !important;
		-moz-text-size-adjust: 100% !important;
	}

	html,
	body,
	#app {
		height: 100%;
	}

	.flex-row {
		display: flex;
		flex-direction: row;
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.align-center {
		align-items: center;
	}

	.align-top {
		align-items: flex-start;
	}

	.align-end {
		align-items: flex-end;
	}

	.space-between {
		justify-content: space-between;
	}

	.space-around {
		justify-content: space-around;
	}

	.justify-center {
		justify-content: center;
	}

	.justify-end {
		justify-content: flex-end;
	}

	.flex-wrap {
		flex-wrap: wrap;
	}

	.relative {
		position: relative;
	}

	.flex-end {
		justify-content: flex-end;
	}

	/*按钮样式开始  */

	button {
		margin: 0;
		padding: 0;
		border: none;
		background: none;
		border-radius: none;
	}

	button::after {
		content: none;
	}

	button[plain] {
		border: none;
	}

	/*按钮样式结束  */

	img {
		vertical-align: middle;
	}

	/* 多行溢出 */

	.more-ellipsis {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}

	/* 蒙层 */
	.model {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		z-index: 1000;
	}

	/* 底部导航开始*/
	.tab-footer-sec {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 20;
		background: #FFFFFF;
		/* border-top: 1px solid rgba(0, 0, 0, 0.25); */
	}

	.tab-footer-btn {
		width: 375px;
		height: 88px;
		background: #FFFFFF;
		font-size: 20px;
		font-weight: bold;
		color: #000000;
		line-height: 20px;
		box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.25);
	}

	.tab-footer-btn-title {
		margin-top: 58px;
	}

	.tab-no-active-img {
		width: 48px;
		height: 48px;
		margin: 5px 0;
	}

	.tab-active-img {
		width: 93px;
		height: 93px;
		position: absolute;
		top: -29px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}




	/* 技术支持开始 */
	.support-sec {
		width: 750px;
		height: 88px;
		background: #000000;
		position: relative;
	}

	.support-icon {
		width: 31px;
		height: 31px;
	}

	.support-dian {
		width: 3px;
		height: 3px;
		background: #FFFFFF;
		margin: 0 10px;
	}

	.support-con {
		font-size: 28px;
		color: #FFFFFF;
		line-height: 28px;
	}

	/* 技术支持结束 */
	/*底部tab*/
	.bot-tab-sec {
		position: fixed;
		bottom: 50px;
		left: 0;
		width: 100%;
		height: 90px;
		background: #FFFFFF;
		z-index: 100;
		border-radius: 10px 10px 0px 0px;
	}

	.bot-tab-item {
		width: 50%;
	}

	.bot-tab-item-icon {
		width: 43px;
		height: 43px;
		margin-bottom: 2px;
	}

	.bot-tab-item-name {
		font-size: 20px;
		color: #000000;
		line-height: 28px;
	}



	/*底部tab结束*/


	/* 导航栏标题结束 */

	.icp {
		font-size: 22px;
		/* color: #000000; */
		color: #6D7278;
		background: #F2F2F2;
		text-align: center;
		position: fixed;
		/* bottom: 88px; */
		bottom: 0;
		left: 0;
		width: 100%;
		height: 50px;
		z-index: 10
	}

	.beiab-img {
		margin-right: 6px;
	}


	.flex-shrink {
		flex-shrink: 0;
	}

	/*图形验证码开始*/
	.verify-model {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		z-index: 1001;
	}

	.code-wrap {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 1002;
		background: #FFFFFF;
		width: 500px;
		height: max-content;
		border-radius: 10px;
		padding: 40px 30px;
		box-sizing: border-box;
	}

	.code-title {
		font-size: 30px;
		font-weight: bold;
		text-align: center;
	}

	.code-img {
		width: 400px;
		height: 200px;
		display: flex;
		margin: 20px auto 15px;
	}

	.code-tips {
		width: 100%;
		text-align: center;
		font-size: 22px;
		color: #999999;
	}

	.code-input-wrap {
		width: 440px;
		height: 100px;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
		padding-left: 30px;
		box-sizing: border-box;
		margin-top: 30px;
	}

	.code-input-wrap input {
		width: 380px;
		height: 94px;
		font-size: 26px;
		color: #000000;
		line-height: 40px;
		padding: 0 30px;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border: none;
		box-sizing: border-box;
	}

	.send-code-btn {
		margin-top: 80px;
		width: 440px;
		height: 80px;
		background: #FF5E38;
		border-radius: 40px;
		font-size: 30px;
		color: #FFFFFF;
		letter-spacing: 2px;
		text-align: center;
		line-height: 80px;
	}

	/*图形验证码结束*/
</style>
