import Vue from 'vue'
import VueRouter from 'vue-router'
// resetFont();
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const routes = [{
		path: '/',
		name: 'home',
		component: () => import('../views/index'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/detail',
		name: 'detail',
		component: () => import('../views/detail'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/support',
		name: 'support',
		component: () => import('../views/support'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/agreement',
		name: 'agreement',
		component: () => import('../views/agreement'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/codesdpay',
		name: 'codesdpay',
		component: () => import('../views/codesdpay'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/test',
		name: 'test',
		component: () => import('../views/test'),
		meta: {
			keepAlive: false
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
